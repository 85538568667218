import React, { ReactElement, useEffect, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@objects/container'
import BreadCrumbs from '@objects/breadcrumb'
import Headline from '@objects/headline'
import { FormattedMessage, useIntl } from 'react-intl'
import FontSize from '@config/theme/definitions/fontSize'
import Copy from '@components/copy'
import {
  $materialShopMeta,
  $materialShopPaths,
  materialShopClearOrderId,
} from '@services/stores/materialShop'
import { useStore } from '@nanostores/react'

const useStyles = makeStyles((theme) => ({
  contentRoot: {
    textAlign: 'left',
    paddingTop: theme.spacing(9),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(20),
    },
  },
  headerIcon: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    marginBottom: theme.spacing(8),
    '& svg': {
      height: '84px',
      width: '84px',
    },
  },
  text: {
    paddingTop: theme.spacing(16),
    fontWeight: 'bold',
  },
  underlinedBtn: {
    borderRadius: 0,
    padding: theme.spacing(0, 0, 1),
    fontSize: FontSize['base'],
    '& svg': {
      height: FontSize['3lg'],
      width: 'auto',
    },
  },
  infos: {
    marginTop: theme.spacing(12),
  },
  infoText: {
    '&  p': {
      margin: 0,
    },
  },
}))

export default function RootIndex(): ReactElement {
  const classes = useStyles()
  const intl = useIntl()
  const materialShopMeta = useStore($materialShopMeta)
  const materialShopPaths = useStore($materialShopPaths)
  const breadcrumbs = useMemo(() => {
    return [
      {
        label: intl.formatMessage({
          id: 'materialshop.breadcrumb.shop.label',
        }),
        link: materialShopPaths.shop,
      },
      {
        label: intl.formatMessage({
          id: 'materialshop.breadcrumb.thanks.label',
        }),
      },
    ]
  }, [])

  useEffect(() => {
    materialShopClearOrderId()
  }, [])

  return (
    <section>
      <Container background={'light'} type="nomargin">
        <BreadCrumbs crumbList={breadcrumbs} />
      </Container>
      <Container width={'sm'} className={classes.contentRoot}>
        <Headline level={1}>
          <FormattedMessage id={'materialshop.thanks.headline'} />
        </Headline>
        <FormattedMessage id={'materialshop.thanks.subline'} />

        {materialShopMeta?.contact && (
          <div className={classes.infos}>
            <Copy
              className={classes.infoText}
              richtext={materialShopMeta.contact}
            />
          </div>
        )}
      </Container>
    </section>
  )
}
